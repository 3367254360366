import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { BreadcrumbItem } from '@scpc/modules/common/components/breadcrumbs/breadcrumbs';

@Component({
  selector: 'scp-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    RouterLink,
  ],
})
export class BreadcrumbsComponent {

  @Input()
  public items: BreadcrumbItem[];

  @Input()
  public loading: boolean;

  protected trackBy(item: BreadcrumbItem): string {
    return item.name + item.icon + item.href;
  }

}

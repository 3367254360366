<div class='scp-breadcrumbs'>
  <div class='scp-breadcrumbs__content'>
    @if (items?.length && !loading) {
      <ol itemscope itemtype='https://schema.org/BreadcrumbList'>
        @for (item of items; track trackBy(item); ) {
          <li itemscope itemtype='https://schema.org/ListItem' itemprop='itemListElement'>
            @if ($last) {
              <h1>{{ item.name | translate }}</h1>
            } @else {
              <a [routerLink]='item.href' itemprop='item' [attr.aria-label]='(item.name || "Top") | translate'>
                @if (item.icon) {
                  <img [src]='item.icon' [alt]='item.name' height='14' width='14'>
                }
                @if (item.name) {
                  {{ item.name | translate }}
                }
              </a>
            }
            <meta itemprop='name' content='{{(item.name || "Top") | translate}}' />
            <meta itemprop='position' content='{{$index + 1}}'>
          </li>
          @if (!$last) {
            <li>
              <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M3 11.2848L3.60668 12L7 8L3.60668 4L3 4.71515L5.78663 8L3 11.2848Z' fill='#94B0BF' />
              </svg>
            </li>
          }
        }
      </ol>
    }
    @if (loading) {
      <div class='ssc-square'></div>
    }
  </div>
</div>
